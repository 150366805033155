.calendar-box:hover{
    background-color: #EBF8FA;
    cursor: pointer;
}

.calendar-box{
    padding: 10px 12px;
    border-radius: 4px;
}

.calendar-img{
    width: 24px;
    height: 24px;
    padding-right: 15px;
}