.ssn-input-container {
  position: relative;
  border-bottom: 1px solid #dcdfe6;
}

.ssn-display {
  gap: 4px;
  width: 100%;
  height: 32px;
  display: flex;
  margin: 12px 8px;
  position: relative;
  align-items: center;
}

.ssn-display:hover {
  cursor: text;
}

.ssn-dash {
  width: 8px;
  height: 2px;
  border-radius: 4px;
  background-color: #c5cbd6;
}

.ssn-placeholder {
  position: absolute;
  left: 0;
}

.cursor {
  width: 1px;
  height: 18px;
  animation: blink 1s step-start infinite;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
