.DayPicker * {
  outline: none;
}

.DayPicker {
  font-family: "Museo";
  font-size: 20px;
}

.DayPicker-Caption {
  font-family: "Museo";
  font-size: 20px;
  font-weight: 700;
  color: #41506f;
}

.DayPicker-Weekdays {
  font-family: "Open Sans";
  font-size: 14px;
  color: #c5cbd6;
}

.DayPicker-Day {
  font-family: "Museo";
  font-size: 20px;
  border-radius: 0 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #0daec8 !important;
  opacity: 0.8 !important;
  color: white !important;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
    .DayPicker-Day--outside
  ):hover {
  opacity: 0.5 !important;
}

.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-width: 1px;
  border-color: #dcdfe6 !important;
  border-style: solid;
  color: #41506f !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #ebf8fa !important;
  color: #41506f !important;
  border-radius: 0px !important;
}

.DayPicker-Day--today {
  font-weight: 500;
}

.DayPicker-Day--from:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #0daec8 !important;
  color: white !important;
}

.DayPicker-Day--to:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #0daec8 !important;
  color: white !important;
}
