@font-face {
  font-family: Museo;
  src: local("Museo500-Regular"), local("Museo 500 Regular"),
    url(./Museo500-Regular.otf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Museo;
  src: local("Museo-700"), local("Museo 700"), url(./Museo-700.otf);
  font-weight: 700;
  font-style: normal;
}
