.treatmentTitle {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #100249;
}

.treatmentDescription {
    padding: 25px;
    font-family: 'Open Sans';
    line-height: 150%;
}

.treatmentFooter {
    height: 64px;
}

/* ======= V2 Modal ======= */

.modalWrapper {
    gap: 16px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.modalTitle {
    top: 0;
    position: sticky;
    padding: 32px 0px 16px 0px;
    border-bottom: 1px solid #dcdfe6;
    background-color: #ffffff;
}

.modalFooter {
    bottom: 0;
    height: auto;
    position: sticky;
    background-color: #ffffff;
    padding: 16px 16px 24px !important;
}

.titleText {
    font-size: 22px;
    color: #301E79;
    font-weight: 600;
    text-align: center;
}

.fotterBtn {
    flex:1;
    height: 48px;
    font-weight: 700;
    color: #41506f;
}
.treatmentDescriptionV2{
    font-family: 'Open Sans';
    line-height: 150%;
}

.noScollBar::-webkit-scrollbar{
    width:0;
    display: none;
}
