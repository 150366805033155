.slick-slider {
  outline: none !important;
}
.slick-slider .slick-track {
  outline: none !important;
}
.slick-list {
  outline: none !important;
}
.slick-slide {
  outline: none !important;
}

.slick-slider .slick-arrow.slick-next {
  right: 19px !important;
}

.slick-slider .slick-arrow.slick-prev {
  left: 19px !important;
  z-index: 1000;
}

.slick-slider .slick-next:before {
  content: none;
}

.slick-slider .slick-prev:before {
  content: none;
}

.slick-slider.thumbnail-slider:not(.slick-vertical) .slick-track {
  display: flex;
  gap: 8px;  
}
