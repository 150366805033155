.modal{
  max-width: 428px;
}

.modalTitle {
  text-align: center;
  font-size: 22px;
  margin-top: 32px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #100249;
}

.content{
  margin: 0;
}

.modalDescription {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  font-family: 'Museo';
  margin: 6px 0;
}

.errorMessage {
  color: #DC3545;
  font-size: 16px;
  margin: 6px 0;
}

.action{
  display: flex;
  align-items: center;
  margin: 6px 0;
}

.checkbox{
  width: 20px;
  height: 20px;
  margin: 0;
  margin-right: 8px;
}

.agreeText:hover {
  cursor: pointer;
}

.disclaimerFooter button{
  padding: 0;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Museo";
  text-transform: none;
  background-color: #FFFFFF;
  border: 1px solid #DCDFE6;
}