@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#updated-location-details {
  border-radius: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

@font-face {
  font-family: Museo;
  src: local("Museo500-Regular"), local("Museo 500 Regular"),
    url(/static/media/Museo500-Regular.e2754d62.otf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Museo;
  src: local("Museo-700"), local("Museo 700"), url(/static/media/Museo-700.8d2cbb04.otf);
  font-weight: 700;
  font-style: normal;
}

/* CSS */
@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: book;
  }
  .apple-pay-button-with-text > * {
    display: none;
  }
  .apple-pay-button-black-with-text {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-white-with-text {
    -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line-with-text {
    -apple-pay-button-style: white-outline;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
    --apple-pay-scale: 1; /* (height / 32) */
    display: inline-flex;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
  }
  .apple-pay-button-black-with-text {
    background-color: black;
    color: white;
  }
  .apple-pay-button-white-with-text {
    background-color: white;
    color: black;
  }
  .apple-pay-button-white-with-line-with-text {
    background-color: white;
    color: black;
    border: 0.5px solid black;
  }
  .apple-pay-button-with-text.apple-pay-button-black-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  .apple-pay-button-with-text.apple-pay-button-white-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-with-text.apple-pay-button-white-with-line-with-text
    > .logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-with-text > .text {
    font-family: -apple-system;
    font-size: calc(1em * var(--apple-pay-scale));
    font-weight: 300;
    align-self: center;
    margin-right: calc(2px * var(--apple-pay-scale));
  }
  .apple-pay-button-with-text > .logo {
    width: calc(35px * var(--scale));
    height: 100%;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 0 50%;
    margin-left: calc(2px * var(--apple-pay-scale));
    border: none;
  }
}

.dropdown {
  overflow-y: overlay;
  max-height: 330px;
}

.dropdown::-webkit-scrollbar {
  -webkit-appearance: none;
}

.dropdown::-webkit-scrollbar:vertical {
  width: 11px;
}

.dropdown::-webkit-scrollbar:horizontal {
  height: 11px;
}

.dropdown::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.clickable{
  color: #0daec8;
  text-decoration: underline;
}

.clickable:hover{
  cursor: pointer;
}
.disabled{
  color: #C5CBD6;
}

.verifyPhonePicker .country-list{
  max-height: 150px;
}

.react-tel-input .selected-flag {
  width: 80px;
  background-color: rgba(174, 174, 174, 0.1);
}
.react-tel-input .selected-flag:hover {
  background-color: rgba(174, 174, 174, 0.1);
}
.react-tel-input .flag-dropdown,.react-tel-input .flag-dropdown.open{
  z-index: 4;
  background-color: rgba(174, 174, 174, 0.1);
}
.react-tel-input .flag-dropdown.open .selected-flag {
      background-color: rgba(174, 174, 174, 0.1);
}
.infoModalTitle {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: #100249;
}
.infoDescriptionWrapper{
  display: flex;
  justify-content: center;
}

.infoModalDescription {
  text-align: center;
  font-family: 'Museo';
  line-height: 150%;
  color: #301E79;
  max-width: 328px;
}

.errorMessage {
  color: tomato;
}

.modalAction{
  padding: 0px 50px;
}

.modalAction input{
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.agreeText:hover {
  cursor: pointer;
}

.infoModalFooter button{
  font-size: 16px;
  padding: 12px 38px;
  text-transform: none;
}

.modalFooter {
  height: 64px;
}
.DayPicker * {
  outline: none;
}

.DayPicker {
  font-family: "Museo";
  font-size: 20px;
}

.DayPicker-Caption {
  font-family: "Museo";
  font-size: 20px;
  font-weight: 700;
  color: #41506f;
}

.DayPicker-Weekdays {
  font-family: "Open Sans";
  font-size: 14px;
  color: #c5cbd6;
}

.DayPicker-Day {
  font-family: "Museo";
  font-size: 20px;
  border-radius: 0 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #0daec8 !important;
  opacity: 0.8 !important;
  color: white !important;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
    .DayPicker-Day--outside
  ):hover {
  opacity: 0.5 !important;
}

.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-width: 1px;
  border-color: #dcdfe6 !important;
  border-style: solid;
  color: #41506f !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #ebf8fa !important;
  color: #41506f !important;
  border-radius: 0px !important;
}

.DayPicker-Day--today {
  font-weight: 500;
}

.DayPicker-Day--from:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #0daec8 !important;
  color: white !important;
}

.DayPicker-Day--to:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #0daec8 !important;
  color: white !important;
}


.scroll-horizontal{
  width: 100%;
  display: flex;
  overflow-x: scroll;
  flex-direction: row;
  padding-bottom: 10px;
}

.scroll-horizontal::-webkit-scrollbar {
  height: 4px;
}

/* Track */
.scroll-horizontal::-webkit-scrollbar-track {
  visibility: hidden;
}
 
/* Handle */
.scroll-horizontal::-webkit-scrollbar-thumb {
  background: rgba(65, 80, 111, 0.6); 
  border-radius: 3px;
}

/* Handle on hover */
.scroll-horizontal::-webkit-scrollbar-thumb:hover {
  background: rgba(65, 80, 111, 0.7);
  cursor: pointer;
}
.MuiDialog-paper::-webkit-scrollbar {
  width: 0;
  display: none;
}
.MuiDialog-paper {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.securityCode {
  width: 100%;
  padding: 18px;
  border: none;
  outline: none;
  text-align: center;
  color: #41506f;
  font-family: "Museo";
  overflow-wrap: break-word;
  font-size: 16px;
  overflow-x: scroll;
  white-space: pre-line;
}

.securityCode:active {
  border: 0;
}

.securityCode::-webkit-input-placeholder {
  white-space: pre-line; /* Allows for line breaks */
}

.securityCode:-ms-input-placeholder {
  white-space: pre-line; /* Allows for line breaks */
}

.securityCode::placeholder {
  white-space: pre-line; /* Allows for line breaks */
}

.securityCode:focus::-webkit-input-placeholder {
  color: transparent;
}

.securityCode:focus:-ms-input-placeholder {
  color: transparent;
}

.securityCode:focus::placeholder {
  color: transparent;
}

.calendar-box:hover{
    background-color: #EBF8FA;
    cursor: pointer;
}

.calendar-box{
    padding: 10px 12px;
    border-radius: 4px;
}

.calendar-img{
    width: 24px;
    height: 24px;
    padding-right: 15px;
}
.treatmentTitle {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #100249;
}

.treatmentDescription {
    padding: 25px;
    font-family: 'Museo';
    line-height: 150%;
}

.treatmentFooter {
    height: 64px;
}
.switch-disable {
  opacity: .45;
  border-radius: 20px;
}
/* Tooltip.css */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  opacity: 0;
  z-index: -999;
  font-size: 10px;
  -webkit-transform: none;
          transform: none;
  position: fixed;
  max-width: 250px;
  overflow: hidden;
  padding: 4px 8px;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  font-family: "Open Sans", sans-serif;
  background-color: rgba(97, 97, 97, 0.92);
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    -webkit-transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    -webkit-transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  z-index: 1;
}

.text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.treatmentTitle {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #100249;
}

.treatmentDescription {
    padding: 25px;
    font-family: 'Open Sans';
    line-height: 150%;
}

.treatmentFooter {
    height: 64px;
}

/* ======= V2 Modal ======= */

.modalWrapper {
    grid-gap: 16px;
    gap: 16px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.modalTitle {
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    padding: 32px 0px 16px 0px;
    border-bottom: 1px solid #dcdfe6;
    background-color: #ffffff;
}

.modalFooter {
    bottom: 0;
    height: auto;
    position: -webkit-sticky;
    position: sticky;
    background-color: #ffffff;
    padding: 16px 16px 24px !important;
}

.titleText {
    font-size: 22px;
    color: #301E79;
    font-weight: 600;
    text-align: center;
}

.fotterBtn {
    flex:1 1;
    height: 48px;
    font-weight: 700;
    color: #41506f;
}
.treatmentDescriptionV2{
    font-family: 'Open Sans';
    line-height: 150%;
}

.noScollBar::-webkit-scrollbar{
    width:0;
    display: none;
}

.treatment-dropdown {
    position: absolute;
    z-index: 999;
    margin-top: -8px;
    width: 100%;
    border-radius: 16px;
    max-height: 470px;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.treatment-dropdown::-webkit-scrollbar {
    -webkit-appearance: none;
}

.treatment-dropdown::-webkit-scrollbar:vertical {
    width: 11px;
}

.treatment-dropdown::-webkit-scrollbar:horizontal {
    height: 11px;
}

.treatment-dropdown::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
}
.treatment-dropdown {
    position: absolute;
    z-index: 999;
    margin-top: -8px;
    width: 100%;
    border-radius: 16px;
    max-height: 470px;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.treatment-dropdown::-webkit-scrollbar {
    -webkit-appearance: none;
}

.treatment-dropdown::-webkit-scrollbar:vertical {
    width: 11px;
}

.treatment-dropdown::-webkit-scrollbar:horizontal {
    height: 11px;
}

.treatment-dropdown::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
}
.modal{
  max-width: 428px;
}

.modalTitle {
  text-align: center;
  font-size: 22px;
  margin-top: 32px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #100249;
}

.content{
  margin: 0;
}

.modalDescription {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  font-family: 'Museo';
  margin: 6px 0;
}

.errorMessage {
  color: #DC3545;
  font-size: 16px;
  margin: 6px 0;
}

.action{
  display: flex;
  align-items: center;
  margin: 6px 0;
}

.checkbox{
  width: 20px;
  height: 20px;
  margin: 0;
  margin-right: 8px;
}

.agreeText:hover {
  cursor: pointer;
}

.disclaimerFooter button{
  padding: 0;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Museo";
  text-transform: none;
  background-color: #FFFFFF;
  border: 1px solid #DCDFE6;
}
.ProAvailable {
    background-color: #ffffff;
}

.ProUnAvailable {
   background-color: #C5CBD6;
}

.ssn-input-container {
  position: relative;
  border-bottom: 1px solid #dcdfe6;
}

.ssn-display {
  grid-gap: 4px;
  gap: 4px;
  width: 100%;
  height: 32px;
  display: flex;
  margin: 12px 8px;
  position: relative;
  align-items: center;
}

.ssn-display:hover {
  cursor: text;
}

.ssn-dash {
  width: 8px;
  height: 2px;
  border-radius: 4px;
  background-color: #c5cbd6;
}

.ssn-placeholder {
  position: absolute;
  left: 0;
}

.cursor {
  width: 1px;
  height: 18px;
  -webkit-animation: blink 1s step-start infinite;
          animation: blink 1s step-start infinite;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@-webkit-keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.wrapper {
  position: relative;
}

.check-wrapper {
  height: 180px;
}

.animation-wrapper {
  width: 100%;
  overflow: hidden;
  position: absolute;
  transition: all 0.7s ease;
}

.expanded {
  height: 300px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.collapsed {
  height: 180px;
  top: 0%;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.action-wrapper {
  overflow: hidden;
  transition: all 0.7s ease;
}

.desc-expanded {
  -webkit-transform: translateY(150%);
          transform: translateY(150%);
}

.desc-collapsed {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

/* therapist block */

.therapistBlock{
  padding:16px;
  background-color:white;
  margin-bottom:16px;
}

.therapistBlock:hover{
  cursor: pointer;
}

.image-wrapper{
  position:relative;
}

.profile-image{
  height:70px; 
  width:70px;
  border-radius:50%;
  margin-right:16px;
  object-fit: cover;
}

.vaccinated-wrapper{
  position:absolute;
  height:20px;
  width:20px;
  bottom:0px;
  right:20px;
}

.vaccinated-icon {
  height: 20px;
  width: 20px;
}

.therapistBlock .therapist-name{
  font-family:"Museo";
  font-weight:600;
  font-size:16px;
}

.therapistBlock .therapist-rating{
  font-family:"Open Sans";
  font-weight:400;
  font-size:16px;
  margin-right: 5px;
}
.slick-slider {
  outline: none !important;
}
.slick-slider .slick-track {
  outline: none !important;
}
.slick-list {
  outline: none !important;
}
.slick-slide {
  outline: none !important;
}

.slick-slider .slick-arrow.slick-next {
  right: 19px !important;
}

.slick-slider .slick-arrow.slick-prev {
  left: 19px !important;
  z-index: 1000;
}

.slick-slider .slick-next:before {
  content: none;
}

.slick-slider .slick-prev:before {
  content: none;
}

.slick-slider.thumbnail-slider:not(.slick-vertical) .slick-track {
  display: flex;
  grid-gap: 8px;
  gap: 8px;  
}

.scroll-vertical {
  height: 100%;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  padding-right: 5px;
  border-width: 1px;
}

.scroll-vertical::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scroll-vertical::-webkit-scrollbar-track {
  visibility: hidden;
}

/* Handle */
.scroll-vertical::-webkit-scrollbar-thumb {
  background: #e0e2e7;
  border-radius: 3px;
}

/* Handle on hover */
.scroll-vertical::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

@font-face {
  font-family: Nunito;
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url(/static/media/Nunito-Regular.e4eb7682.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Nunito;
  src: local("Nunito Semibold"), local("Nunito-Semibold"),
    url(/static/media/Nunito-SemiBold.06337b2e.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Nunito;
  src: local("Nunito Bold"), local("Nunito-Bold"), url(/static/media/Nunito-Bold.7ddd6722.ttf);
  font-weight: 700;
  font-style: normal;
}



@font-face {
  font-family: "SF UI Text";
  src: local("SFUIText-Medium"), url(/static/media/SFUIText-Medium.4ea10fa2.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Text";
  src: local("SFUIText-Regular"), url(/static/media/SFUIText-Regular.96141c83.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: WorkSans;
  src: local("WorkSans-SemiBold"), url(/static/media/WorkSans-SemiBold.6059d691.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: WorkSans;
  src: local("WorkSans-Light"), url(/static/media/WorkSans-Light.129690d7.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: WorkSans;
  src: local("WorkSans-Regular"), url(/static/media/WorkSans-Regular.7c5aca8a.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: WorkSans;
  src: local("WorkSans-Medium"), url(/static/media/WorkSans-Medium.905f58df.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: WorkSans;
  src: local("WorkSans-Bold"), url(/static/media/WorkSans-Bold.1cdef491.ttf);
  font-weight: 700;
  font-style: normal;
}

