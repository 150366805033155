
.scroll-horizontal{
  width: 100%;
  display: flex;
  overflow-x: scroll;
  flex-direction: row;
  padding-bottom: 10px;
}

.scroll-horizontal::-webkit-scrollbar {
  height: 4px;
}

/* Track */
.scroll-horizontal::-webkit-scrollbar-track {
  visibility: hidden;
}
 
/* Handle */
.scroll-horizontal::-webkit-scrollbar-thumb {
  background: rgba(65, 80, 111, 0.6); 
  border-radius: 3px;
}

/* Handle on hover */
.scroll-horizontal::-webkit-scrollbar-thumb:hover {
  background: rgba(65, 80, 111, 0.7);
  cursor: pointer;
}