.treatment-dropdown {
    position: absolute;
    z-index: 999;
    margin-top: -8px;
    width: 100%;
    border-radius: 16px;
    max-height: 470px;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.treatment-dropdown::-webkit-scrollbar {
    -webkit-appearance: none;
}

.treatment-dropdown::-webkit-scrollbar:vertical {
    width: 11px;
}

.treatment-dropdown::-webkit-scrollbar:horizontal {
    height: 11px;
}

.treatment-dropdown::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
}