.scroll-vertical {
  height: 100%;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  padding-right: 5px;
  border-width: 1px;
}

.scroll-vertical::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scroll-vertical::-webkit-scrollbar-track {
  visibility: hidden;
}

/* Handle */
.scroll-vertical::-webkit-scrollbar-thumb {
  background: #e0e2e7;
  border-radius: 3px;
}

/* Handle on hover */
.scroll-vertical::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}
