/* therapist block */

.therapistBlock{
  padding:16px;
  background-color:white;
  margin-bottom:16px;
}

.therapistBlock:hover{
  cursor: pointer;
}

.image-wrapper{
  position:relative;
}

.profile-image{
  height:70px; 
  width:70px;
  border-radius:50%;
  margin-right:16px;
  object-fit: cover;
}

.vaccinated-wrapper{
  position:absolute;
  height:20px;
  width:20px;
  bottom:0px;
  right:20px;
}

.vaccinated-icon {
  height: 20px;
  width: 20px;
}

.therapistBlock .therapist-name{
  font-family:"Museo";
  font-weight:600;
  font-size:16px;
}

.therapistBlock .therapist-rating{
  font-family:"Open Sans";
  font-weight:400;
  font-size:16px;
  margin-right: 5px;
}