.treatmentTitle {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #100249;
}

.treatmentDescription {
    padding: 25px;
    font-family: 'Museo';
    line-height: 150%;
}

.treatmentFooter {
    height: 64px;
}