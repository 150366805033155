@font-face {
  font-family: "SF UI Text";
  src: local("SFUIText-Medium"), url(./SFUIText-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Text";
  src: local("SFUIText-Regular"), url(./SFUIText-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}
