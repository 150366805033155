/* Tooltip.css */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  opacity: 0;
  z-index: -999;
  font-size: 10px;
  transform: none;
  position: fixed;
  max-width: 250px;
  overflow: hidden;
  padding: 4px 8px;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  font-family: "Open Sans", sans-serif;
  background-color: rgba(97, 97, 97, 0.92);
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  z-index: 1;
}

.text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
