.clickable{
  color: #0daec8;
  text-decoration: underline;
}

.clickable:hover{
  cursor: pointer;
}
.disabled{
  color: #C5CBD6;
}

.verifyPhonePicker .country-list{
  max-height: 150px;
}

.react-tel-input .selected-flag {
  width: 80px;
  background-color: rgba(174, 174, 174, 0.1);
}
.react-tel-input .selected-flag:hover {
  background-color: rgba(174, 174, 174, 0.1);
}
.react-tel-input .flag-dropdown,.react-tel-input .flag-dropdown.open{
  z-index: 4;
  background-color: rgba(174, 174, 174, 0.1);
}
.react-tel-input .flag-dropdown.open .selected-flag {
      background-color: rgba(174, 174, 174, 0.1);
}