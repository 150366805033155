.securityCode {
  width: 100%;
  padding: 18px;
  border: none;
  outline: none;
  text-align: center;
  color: #41506f;
  font-family: "Museo";
  overflow-wrap: break-word;
  font-size: 16px;
  overflow-x: scroll;
  white-space: pre-line;
}

.securityCode:active {
  border: 0;
}

.securityCode::placeholder {
  white-space: pre-line; /* Allows for line breaks */
}

.securityCode:focus::placeholder {
  color: transparent;
}
