@font-face {
  font-family: WorkSans;
  src: local("WorkSans-SemiBold"), url(./WorkSans-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: WorkSans;
  src: local("WorkSans-Light"), url(./WorkSans-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: WorkSans;
  src: local("WorkSans-Regular"), url(./WorkSans-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: WorkSans;
  src: local("WorkSans-Medium"), url(./WorkSans-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: WorkSans;
  src: local("WorkSans-Bold"), url(./WorkSans-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}
