.infoModalTitle {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: #100249;
}
.infoDescriptionWrapper{
  display: flex;
  justify-content: center;
}

.infoModalDescription {
  text-align: center;
  font-family: 'Museo';
  line-height: 150%;
  color: #301E79;
  max-width: 328px;
}

.errorMessage {
  color: tomato;
}

.modalAction{
  padding: 0px 50px;
}

.modalAction input{
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.agreeText:hover {
  cursor: pointer;
}

.infoModalFooter button{
  font-size: 16px;
  padding: 12px 38px;
  text-transform: none;
}

.modalFooter {
  height: 64px;
}