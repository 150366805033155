@font-face {
  font-family: Nunito;
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url(./Nunito-Regular.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Nunito;
  src: local("Nunito Semibold"), local("Nunito-Semibold"),
    url(./Nunito-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Nunito;
  src: local("Nunito Bold"), local("Nunito-Bold"), url(./Nunito-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}
