.wrapper {
  position: relative;
}

.check-wrapper {
  height: 180px;
}

.animation-wrapper {
  width: 100%;
  overflow: hidden;
  position: absolute;
  transition: all 0.7s ease;
}

.expanded {
  height: 300px;
  top: 50%;
  transform: translateY(-50%);
}

.collapsed {
  height: 180px;
  top: 0%;
  transform: translateY(0%);
}

.action-wrapper {
  overflow: hidden;
  transition: all 0.7s ease;
}

.desc-expanded {
  transform: translateY(150%);
}

.desc-collapsed {
  transform: translateY(0%);
}
