.dropdown {
  overflow-y: overlay;
  max-height: 330px;
}

.dropdown::-webkit-scrollbar {
  -webkit-appearance: none;
}

.dropdown::-webkit-scrollbar:vertical {
  width: 11px;
}

.dropdown::-webkit-scrollbar:horizontal {
  height: 11px;
}

.dropdown::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
